@import url(https://cdn.jsdelivr.net/gh/moonspam/NanumSquare@1.0/nanumsquare.css);
 @font-face {
	 font-family: "Vezla";
	 font-display: auto;
	 src: url("Vezla.ttf") format("truetype");
	 font-style: normal;
	 font-weight: normal;
}
 * {
	 box-sizing: border-box;
	 font-family: "NanumSquare", sans-serif;
}
 #root {
	 overflow-x: hidden;
}
 div#top-container {
	 width: 100%;
	 min-height: 100vh;
	 background-image: url("main.jpg");
	 background-position: center;
	 background-size: cover;
}
 div#top-container.question {
	 background-image: url("question.jpg");
}
 div#top-container.reply {
	 background-image: url("reply.jpg");
}
 .logo {
	 width: 60px;
}
 .logo-cooldoc {
	 width: 100%;
}
 div.container {
	 width: 100%;
	 max-width: 420px;
	 margin: auto;
	 padding: 0 15px 80px;
	 text-align: center;
}
 div.container.intro, div.container.reply {
	 padding-top: 55px;
}
 div.container.test {
	 padding: 20px 0 0;
	 max-width: 320px;
}
 div.container.result {
	 padding: 40px 15px;
}
 img.whoami {
	 margin-bottom: -30px;
	 width: 100%;
	 max-width: 350px;
}
 img.sub-title {
	 width: 70%;
	 opacity: 0;
	 height: 90px;
	 object-fit: contain;
	 max-width: 350px;
	 margin-bottom: 0;
}
 img.sub-title.visible {
	 opacity: 1;
}
 .intro-text {
	 margin-top: -30px;
}
 .intro-text > img {
	 width: 100%;
}
 section.lang-container {
	 display: flex;
	 justify-content: center;
	 color: #989bc7;
	 font-size: 20px;
	 line-height: 25px;
	 margin-bottom: 21px;
}
 section.lang-container > button {
	 font-family: black-han-sans, sans-serif;
	 margin: 0 10px;
	 background-color: transparent;
	 border: none;
	 font-weight: bold;
	 color: #989bc7;
	 font-size: 20px;
	 line-height: 25px;
}
 section.lang-container > button.active {
	 color: #fff;
}
 button {
	 cursor: pointer;
}
 button.cta {
	 font-family: black-han-sans, sans-serif;
	 font-weight: 400;
	 font-style: normal;
	 width: 100%;
	 max-width: 400px;
	 padding: 30px;
	 border-radius: 4px;
	 background: linear-gradient(180deg, rgba(34, 46, 146, 0.5) 0%, rgba(0, 0, 0, 0.175) 100%);
	 color: #fff;
	 font-size: 32px;
	 border: 1px solid #fff;
	 transition: 0.3s ease-in;
}
 @media (hover) {
	 button.cta:hover {
		 background-color: white;
		 color: black;
	}
}
 h2.input-header {
	 font-weight: 800;
	 font-size: 18px;
	 line-height: 23px;
	 color: #fff;
	 margin-bottom: 63px;
	 white-space: pre-line;
	 word-break: keep-all;
}
 div.row-container {
	 margin-bottom: 167px;
}
 div.row {
	 display: flex;
	 gap: 8px;
	 align-items: center;
	 color: #fff;
	 width: 100%;
	 margin-bottom: 12px;
	 flex-direction: column;
	 align-items: flex-start;
}
 div.row > label:not(.check-agreement) {
	 text-transform: capitalize;
	 display: block;
	 text-align: left;
	 width: 100%;
	 font-weight: bold;
}
 div.row > .input-field {
	 display: flex;
	 height: 47px;
	 gap: 8px;
}
 div.row > .input-field > button {
	 width: 80px;
	 border-radius: 4px;
	 border: 1px solid #fff;
}
 div.row > .input-field > button.active {
	 background-color: #0132bb;
	 color: #fff;
	 border-color: #0132bb;
}
 div.row.agreement {
	 flex-direction: row;
	 margin-bottom: 14px;
}
 div.row input:not([type="checkbox"]), div.row select {
	 width: 100%;
	 padding: 15px;
	 border: 1px solid #fff;
	 border-radius: 4px;
	 background-color: #000 b8;
	 color: white;
}
 div.row label.check-agreement {
	 text-align: left;
}
 .progress-container {
	 width: 100%;
	 max-width: 312px;
	 height: 1px;
	 background-color: #e1f4f64d;
	 margin: 20px auto 0;
}
 .progress-container .indicator {
	 width: 9px;
	 height: 9px;
	 background-color: #46eaff;
	 border-radius: 4.5px;
	 position: relative;
	 top: -4px;
}
 section.quiz-container {
	 display: flex;
	 transform: translate3d(0px, 0px, 0px);
	 transition: 0.3s ease-in;
}
 section.quiz-container .quiz-item {
	 float: left;
	 width: 320px;
	 opacity: 0;
	 transition: 0.3s ease-in;
}
 section.quiz-container .quiz-item.active {
	 opacity: 1;
}
 img.point-down {
	 margin-top: 46px;
	 display: block;
	 margin-left: 29px;
	 position: relative;
	 z-index: 1;
}
 div.q-title {
	 width: 320px;
	 height: 200px;
	 margin-top: -48px;
	 border: 1px solid #aaa;
	 display: flex;
	 justify-content: center;
	 align-items: center;
	 color: #fff;
	 font-weight: 800;
	 font-size: 20px;
	 padding: 20px;
	 word-break: keep-all;
	 margin-bottom: 60px;
	 border-radius: 4px;
}
 button.answer {
	 border-radius: 4px;
	 border: 1px solid #aaa;
	 background-color: transparent;
	 padding: 20px;
	 font-size: 16px;
	 word-break: keep-all;
	 color: #fff;
	 margin-bottom: 12px;
	 width: 320px;
	 transition: 0.3s ease-in;
}
 @media (hover) {
	 button.answer:hover {
		 background-color: white;
		 color: black;
	}
}
 .title-container {
	 display: flex;
	 align-items: flex-end;
	 margin-bottom: -48px;
}
 h1.result-header {
	 text-align: right;
	 position: relative;
	 top: -45px;
	 width: 100%;
	 color: #fff;
	 left: -15px;
}
 h1.result-header .neon {
	 text-transform: uppercase;
	 font-size: 36px;
	 text-shadow: 0px 0px 4px #00d1ff;
	 position: relative;
	 left: -8px;
}
 h1.result-header .sub-text {
	 font-weight: 800;
	 font-size: 18px;
	 line-height: 20px;
}
 .main-box {
	 background: linear-gradient(180deg, #171f55 0%, rgba(0, 0, 0, 0) 100%);
	 border: 1px solid #394b8a;
	 box-sizing: border-box;
	 border-radius: 4px;
	 padding: 62px 20px;
	 color: #fff;
	 text-align: left;
	 white-space: break-spaces;
	 line-height: 20px;
}
 .partner-container {
	 display: flex;
	 gap: 20px;
	 justify-content: center;
	 margin: 40px auto;
}
 .partner-container .partner-box {
	 width: 100%;
	 background: linear-gradient(180deg, #171f55 0%, rgba(0, 0, 0, 0) 100%);
	 border: 1px solid #394b8a;
	 background-repeat: no-repeat;
	 background-position: center;
	 min-width: 148px;
	 height: 115.52px;
	 display: flex;
	 justify-content: center;
	 align-items: center;
	 flex-direction: column;
	 padding-top: 13px;
}
 .partner-container .partner-box > h2 {
	 margin: 0 0 6px;
	 font-weight: 800;
	 font-size: 18px;
	 line-height: 23px;
	 color: #1877f2;
}
 .partner-container .partner-box > h3 {
	 margin: 0;
	 font-weight: 800;
	 font-size: 36px;
	 line-height: 41px;
	 text-transform: uppercase;
	 color: #fff;
}
 .share {
	 display: flex;
	 gap: 25px;
	 justify-content: center;
	 margin: auto auto 40px;
}
 .share a {
	 width: 56px;
	 height: 56px;
}
 .share a img {
	 width: inherit;
	 height: inherit;
}
 #loading-container {
	 width: 100%;
	 height: 100vh;
	 display: flex;
	 justify-content: center;
	 align-items: center;
	 flex-direction: column;
}
 #loading-container .loading-text {
	 color: white;
	 margin-top: 20px;
	 font-weight: bold;
	 font-size: 20px;
}
 #loading-container .lds-default {
	 display: inline-block;
	 position: relative;
	 width: 80px;
	 height: 80px;
}
 #loading-container .lds-default div {
	 position: absolute;
	 width: 6px;
	 height: 6px;
	 background: #fff;
	 border-radius: 50%;
	 animation: lds-default 1.2s linear infinite;
}
 #loading-container .lds-default div:nth-child(1) {
	 animation-delay: 0s;
	 top: 37px;
	 left: 66px;
}
 #loading-container .lds-default div:nth-child(2) {
	 animation-delay: -0.1s;
	 top: 22px;
	 left: 62px;
}
 #loading-container .lds-default div:nth-child(3) {
	 animation-delay: -0.2s;
	 top: 11px;
	 left: 52px;
}
 #loading-container .lds-default div:nth-child(4) {
	 animation-delay: -0.3s;
	 top: 7px;
	 left: 37px;
}
 #loading-container .lds-default div:nth-child(5) {
	 animation-delay: -0.4s;
	 top: 11px;
	 left: 22px;
}
 #loading-container .lds-default div:nth-child(6) {
	 animation-delay: -0.5s;
	 top: 22px;
	 left: 11px;
}
 #loading-container .lds-default div:nth-child(7) {
	 animation-delay: -0.6s;
	 top: 37px;
	 left: 7px;
}
 #loading-container .lds-default div:nth-child(8) {
	 animation-delay: -0.7s;
	 top: 52px;
	 left: 11px;
}
 #loading-container .lds-default div:nth-child(9) {
	 animation-delay: -0.8s;
	 top: 62px;
	 left: 22px;
}
 #loading-container .lds-default div:nth-child(10) {
	 animation-delay: -0.9s;
	 top: 66px;
	 left: 37px;
}
 #loading-container .lds-default div:nth-child(11) {
	 animation-delay: -1s;
	 top: 62px;
	 left: 52px;
}
 #loading-container .lds-default div:nth-child(12) {
	 animation-delay: -1.1s;
	 top: 52px;
	 left: 62px;
}
 @keyframes lds-default {
	 0%, 20%, 80%, 100% {
		 transform: scale(1);
	}
	 50% {
		 transform: scale(1.5);
	}
}
 